<template>
  <div>
    <MultipleUsersModal
      :isActive="showGenerateModal"
      @submit="generateUsersRequest"
      @close="closeModal"
    />
    <div title="Generate Organization Users" class="mb-2">
      <div class="custom-container">
        <h1>Generate Multiple Users</h1>
        <div>
          <va-button class="justify-center" @click="goBack()">
            <i
              class="fa fa-arrow-left pr-2"
              style="color: rgb(255, 255, 255);"
            />
            Go Back
          </va-button>
          <va-button @click="addMoreUsers()">
            <i class="fa fa-plus pr-2" style="color: rgb(255, 255, 255);" /> Add
            More Users
          </va-button>
          <va-button
            @click="saveAndGenerateUsers()"
            :disabled="allFieldsFilled"
          >
            <i class="fa fa-save pr-2" style="color: rgb(255, 255, 255);" />
            Save & Generate Users
          </va-button>
        </div>
      </div>
    </div>

    <div class="user-container mb-2" v-for="(user, index) in users">
      <div class="custom-header">
        <h1 class="text-sm">Generated User #{{ index + 1 }}</h1>
        <va-button
          :disabled="users.length < 2"
          @click="removeUser(index)"
          class="justify-end"
        >
          <i class="fa fa-close pr-2" style="color: rgb(255, 255, 255);" />
          Remove User
        </va-button>
      </div>

      <va-input
        label="Name"
        v-model="user.name"
        placeholder="i.e Johgn Doe "
        required
      >
        <va-icon slot="prepend" color="gray" name="fa fa-vcard" />
      </va-input>
      <va-input
        label="Phone Number"
        @input="maskPhone(user.phoneNumber, index)"
        maxlength="12"
        v-model="user.phoneNumber"
        placeholder="i.e Johgn Doe "
        required
      >
        <va-icon slot="prepend" color="gray" name="fa fa-vcard" />
      </va-input>
      <va-input
        label="E-mail"
        type="email"
        v-model="user.email"
        placeholder="i.e john@doe.com "
        required
      >
        <va-icon slot="prepend" color="gray" name="fa fa-vcard" />
      </va-input>
      <div class="c-input-wrapper pb-2">
        <va-input
          class="pr-2"
          label="City"
          v-model="user.city"
          placeholder="i.e Lafayette "
        >
          <va-icon slot="prepend" color="gray" name="fa fa-address-card" />
        </va-input>
        <va-select
          height="50px"
          background-color="white"
          width="50%"
          label="State"
          v-model="user.state"
          text-by="name"
          key-by="name"
          placeholder="i.e Louisiana "
          :options="states"
        >
        </va-select>
      </div>
      <div class="c-input-wrapper">
        <va-input
          class="pr-2"
          label="Address"
          v-model="user.address"
          placeholder="3909 Ambassador Caffery Pkwy "
        >
          <va-icon slot="prepend" color="gray" name="fa fa-address-card" />
        </va-input>

        <va-input
          label="Zip Code"
          v-model="user.zipcode"
          type="number"
          placeholder="70508 "
        >
          <va-icon slot="prepend" color="gray" name="fa fa-vcard" />
        </va-input>
      </div>
      <va-select
        :label="'Credential'"
        text-by="displayName"
        key-by="value"
        placeholder="i.e Registered Nurse"
        v-model="user.credential"
        :options="credentialOptions"
      >
        <va-icon slot="prepend" color="gray" name="fa fa-vcard" />
      </va-select>
    </div>
  </div>
</template>

<script>
import states from "../../data/states.json";
import countries from "../../data/CountriesList";
import Edit from "./Edit.vue";
import firebase from "firebase";
import axios from "@/scripts/interceptor.js";
import MultipleUsersModal from "./MultipleUsersModal.vue";
export default {
  data() {
    return {
      countries,
      states: states,
      credentialOptions: [],
      showGenerateModal: false,
      users: [
        {
          name: "",
          phoneNumber: "",
          email: "",
          credential: "",
          address: "",
          city: "",
          zipcode: "",
          state: ""
        }
      ],
      organization: {
        name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        phone: "",
        comments: "",
        active: true
      }
    };
  },
  components: {
    MultipleUsersModal
  },
  async created() {
    if (!this.$route.params.uid) {
      this.$router.push("/organizations");
    }
    let response = await axios
      .get(`/api/organizations/admin/details/${this.$route.params.uid}`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        this.throwError(
          "Organization not found",
          "The organization you are looking for does not exist"
        );
      });
    if (!response.data) {
      this.throwError(
        "Organization not found",
        "The organization you are looking for does not exist"
      );
    }
    await this.getCredentialOptions();
    let data = {
      name: response.data?.name,
      address: response.data?.address,
      city: response.data?.city,
      state: response.data?.state,
      zip: response.data?.zip,
      country: response.data?.country,
      phone: response.data?.phone,
      comments: response.data?.comments,
      active: response.data?.active
    };
    this.organization = data;
  },
  methods: {
    maskPhone(model, index) {
      this.users[index].phoneNumber = this.users[index].phoneNumber.replace(/[^0-9]/g, '');
      if (this.users[index].phoneNumber.length > 3 && this.users[index].phoneNumber.length <= 6) {
        this.users[index].phoneNumber = `${this.users[index].phoneNumber.slice(0, 3)}-${this.users[index].phoneNumber.slice(3)}`;
      } else if (this.users[index].phoneNumber.length > 6) {
        this.users[index].phoneNumber = `${this.users[index].phoneNumber.slice(0, 3)}-${this.users[index].phoneNumber.slice(3, 6)}-${this.users[index].phoneNumber.slice(6)}`;
      }
    },
    throwError(title, message) {
      this.$swal({
        title: title,
        text: message,
        icon: "error",
        confirmButtonText: "Ok"
      }).then(() => {
        this.$router.push("/organizations");
      });
    },
    removeUser(index) {
      if (this.users.length < 2) {
        this.$swal.fire({
          title: "Cannot remove user",
          text: "You must have at least one user",
          icon: "error",
          confirmButtonText: "Ok"
        });
        return;
      }
      this.users.splice(index, 1);
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    validateFields(users) {
      users.forEach(user => {
        //check for empty fields
        if (this.compareFields(user)) {
          throw "All fields are required";
        }
        //check if user.email is valid
        if (!this.validateEmail(user.email)) {
          throw "Invalid email address for user " + (user.name || "N/A");
        }
      });
    },

    async generateUsersRequest(data) {
      try {
        if (!data.secret || data.secret.length < 3) {
          this.$swal.fire({
            title: "Invalid Secret",
            text: "Please enter a valid secret",
            icon: "error",
            confirmButtonText: "Ok"
          });
          return;
        }
        this.$swal({
          title: "Updating...",
          html: "Please wait while we generate users",
          allowOutsideClick: false,
          onOpen: () => {
            this.$swal.showLoading();
          }
        });
        this.users.forEach(user => {
          user.email = user.email.toLowerCase();
        });
        const response = await axios.post(
          `/api/organizations/users/generate/${this.$route.params.uid}`,
          {
            users: this.users,
            order: data.secret
          }
        );
        if (response.status === 200) {
          const res = response.data.data
          this.$swal
            .fire({
              title: "Users Generated",
              html: `Users have been generated successfully, you can view them in the procedure details page <br/>
              <b>Procedure</b>: ${res.precedure_id} <br/>
              <b>Default Password</b>: ${res.defaultPassword} <br/>
              <b>Details</b>: <a href="/organizations/procedure/details/${res.organization}/${res.precedure_id}">Procedure Details Page</a>
            `,
              icon: "success",
              confirmButtonText: "Go to Procedure Details"
            })
            .then(() => {
              this.$router.push(
                `/organizations/procedure/details/${res.organization}/${res.precedure_id}`
              );
            });
        } else {
          throw "An error occured while generating users";
        }
      } catch (error) {
        this.$swal.fire({
          title: "Oops, something went wrong!",
          text: error.response.data.message || error,
          icon: "info",
          confirmButtonText: "Ok"
        });
        return;
      }
    },
    async getCredentialOptions(option = true) {
      this.$db.collection('credentials').where('active', '==', option).get().then((snapshot) => {
        snapshot.forEach((doc) => {
          this.credentialOptions.push(doc.data());
        });
      });
    },
    /**
     * @description Save the users and generate them
     * @param {Array} users
    */
    async saveAndGenerateUsers() {
      try {
        this.validateFields(this.users);
        this.users.forEach(user => { //for each this.users change user.credentials to user.credentials.accreditorType
          user.credential = user.credential.accreditorType;
          user.state = user.state.abbreviation;
        });
        this.showGenerateModal = true;
      } catch (error) {
        this.$swal.fire({
          title: "Oops, something went wrong!",
          text: error,
          icon: "info",
          confirmButtonText: "Ok"
        });
      }
    },
    addMoreUsers() {
      this.users.push({
        name: "",
        phoneNumber: "",
        address: "",
        city: "",
        zipcode: "",
        state: "",
        email: "",
        credential: "",
      });
    },
    closeModal() {
      this.showGenerateModal = false;
    },
    goBack() {
      this.$router.push({
        name: "edit",
        params: { uid: this.$route.params.uid }
      });
    },
    compareFields(user) {
      return (
        user.name === "" ||
        user.phoneNumber === "" ||
        user.email === "" ||
        user.credential === "" ||
        user.address === "" ||
        user.city === "" ||
        user.zipcode === "" ||
        user.state === ""
      );
    }
  },
  computed: {
    allFieldsFilled() {
      return this.users.some(user => {
        return this.compareFields(user);
      });
    }
  }
};
</script>
<style scoped>
.custom-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff;
}

.c-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.custom-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 5px;
  background-color: #ffffff;
}

.user-container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
}

.justify-end {
  justify-content: flex-end;
}

.va-select__dropdown {
  background: transparent !important;
}
</style>
